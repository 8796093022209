import React, { Component } from "react";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import ProjectData from "../../content/project-data";
import { HeaderFontCss, ParagraphFontCss } from "../Utils/CssStyleTemplateUtil";
import * as Widget from "./Widget";
import constants from "../constants";
import * as Icon from "./Icon";
import { StaticImage } from "./StaticImage";

class ProjectsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectItems: ProjectData.map(p => [p]),
    };
  }

  componentDidMount() {
    this._recalculateItem();
    window.onresize = this._recalculateItem;
  }

  render() {
    let { projectItems } = this.state;
    return (
      <Wrapper>
        <div className="carousel-wrapper">
          <Carousel
            wrapAround
            pauseOnHover
            renderCenterLeftControls={({ previousSlide }) => (
              <div onClick={previousSlide} style={{ cursor: "pointer" }}>
                <Icon.KeyboardArrowLeft size={22} color={"grey"} />
              </div>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <div onClick={nextSlide} style={{ cursor: "pointer" }}>
                <Icon.KeyboardArrowRight size={22} color={"grey"} />
              </div>
            )}
            renderBottomCenterControls={this._renderCarouselPagingIndicator}
          >
            {projectItems.map((projectsPerPage, idxOfPage) => (
              <div className="items-wrapper" key={idxOfPage}>
                {projectsPerPage.map((project, idx) => (
                  <a
                    key={idx}
                    className="item-wrapper"
                    target="_blank"
                    href={
                      project.hasOwnProperty("description_tw")
                        ? `${constants.webUrl}/project/${project.id}`
                        : `${constants.webUrl}/project`
                    }
                  >
                    <div className="image">
                      {project.cover_img && (
                        <StaticImage
                          style={{ width: "100%", height: "100%" }}
                          path={"/project/"}
                          name={project.cover_img}
                        />
                      )}
                    </div>
                    <h3>{project.title_tw}</h3>
                    {/* <p>{project.description_tw}</p> */}
                  </a>
                ))}
              </div>
            ))}
          </Carousel>
        </div>
      </Wrapper>
    );
  }

  _recalculateItem = () => {
    const _reconstructItem = (items, itemPerPage = 1) => {
      let _projectItems = [];
      items.reduce((acc, curr, idx) => {
        if (idx % itemPerPage === 0) {
          acc.push([curr]);
        } else {
          acc[Math.floor(idx / itemPerPage)].push(curr);
        }
        return acc;
      }, _projectItems);
      return _projectItems;
    };
    let items = [];
    if (window.innerWidth > 900) {
      items = _reconstructItem(ProjectData, 3);
    } else if (window.innerWidth > 600) {
      items = _reconstructItem(ProjectData, 2);
    } else {
      items = _reconstructItem(ProjectData, 1);
    }
    this.setState({
      projectItems: items,
    });
  };

  _renderCarouselPagingIndicator = ({
    slideCount,
    goToSlide,
    currentSlide,
  }) => {
    return (
      <CarouselPagingIndicators activeIdx={currentSlide}>
        {new Array(slideCount).fill(0).map((e, idx) => (
          <div
            className={`dot${idx === currentSlide ? " active" : ""}`}
            key={idx}
            onClick={() => currentSlide !== idx && goToSlide(idx)}
          />
        ))}
      </CarouselPagingIndicators>
    );
  };
}

const Wrapper = styled.div`
  padding: 20px 40px;
  & .carousel-wrapper {
    & > div:focus {
      border: none;
      outline: none;
    }
  }

  & .items-wrapper {
    display: flex;

    & .item-wrapper {
      padding: 20px;
      flex: 1;
      height: 400px;

      cursor: pointer;
      text-decoration: none;

      & h3 {
        margin-bottom: 10px;
        font-size: 20px;
        color: #4e4e4e;
        ${HeaderFontCss}
      }

      & p {
        font-size: 18px;
        color: #4e4e4e;
        ${ParagraphFontCss}
      }

      & .image {
        height: 300px;
      }
    }
  }
`;

const CarouselPagingIndicators = styled.div`
  display: flex;
  & .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0px 5px;
    background-color: #b5b5b5;
    opacity: 0.8;
    transition: 0.7s background-color;
    cursor: pointer;
  }

  & .dot.active {
    background-color: white;
    box-shadow: -1px 5px 9px rgba(0, 0, 0, 0.31);
  }
`;

export default ProjectsSection;
